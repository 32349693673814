.leadTitle {
  font-weight: 200;
}

.mainLogo {
  max-height: 64px;
}

.header {
  margin-top: 75px;
}
